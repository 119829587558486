

<!-- facilities -->


<!-- video -->
<div class="video">
    <video autoplay loop muted id="myVideo" *ngIf="facilitiesPageData && facilitiesPageData.banner && facilitiesPageData.banner.media">
        <source src="{{facilitiesPageData && facilitiesPageData.banner && facilitiesPageData.banner.media && url+facilitiesPageData.banner.media || ''}}" type="video/mp4">
      </video>
      <div class="opacity-overlay">
    </div>
          <div class="text-container">
              <div class="title-text container">
                 <div class="row">
                   <div class="col-lg-4">
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="400">
                    <p class="details-highlight">{{facilitiesPageData && facilitiesPageData.banner && facilitiesPageData.banner.title || ''}}</p>
                    <p class="small-text">
                        {{facilitiesPageData && facilitiesPageData.banner && facilitiesPageData.banner.description || ''}}</p>    
                </div>
                    </div>
    
                 </div>
              </div>
          </div>
</div>
<!-- facilities -->

<div *ngFor="let facility of facilities;let i = index">
    <div class="technology bg-red cm-padding" *ngIf="i % 2 === 0">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-lg-7 order-2 order-lg-1">
                    <div class="description-box">
                    <h1 class="large-heading">{{facility.title}}</h1>
                    <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                    <p class="text-justify" [innerHTML]="facility.description">
                    </p>
                    </div>
                    </div>
                </div>
                <div class="col-lg-5 order-1 order-lg-2">
                    <div class="img-container">
                        <img [src]="url+facility.image" [alt]="'Triveni Spinning Mills Nepal'+facility.title">
                    </div>
                </div>
            </div>
        </div>
</div>
<div class="technology bg-red capacity cm-padding" *ngIf="i % 2 !== 0">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-7 order-2">
                <div class="description-box">
                <h1 class="large-heading text-red">{{facility.title}}</h1>
                <div data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                <p class="text-justify" [innerHTML]="facility.description">
                    </p>
                </div>
                </div>
            </div>
            <div class="col-lg-5 order-1">
                <div class="img-container">
                    <img [src]="url+facility.image" [alt]="'Triveni Spinning Mills Nepal'+facility.title">
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="i !== (facilities.length-1)" class="bg-red">
    <div class="container line">
    </div>
</div>
</div>



<!-- gallery -->
<div class="gallery fixed-bg">
    <div class="white-overlay">
   <div class="container">
       <div class="fixed-box">
    <h1 class="large-heading text-red">Gallery</h1>
    <div class="gallery-container">
        <div *ngFor="let image of imagesFixed; let i = index">
            <div class="single-left" *ngIf="image['type']==='single-left'">
                <div class="row no-gutters">
                    <div class="col-6 image-container">
                        <div class="lg-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                            <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+0)">
                                    <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills gallery">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="sm-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 1">
                            <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+1)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                        <div class="sm-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 2">
                            <img [src]="url+image['images'][2].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+2)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-right" *ngIf="image['type']==='single-right'">
                <div class="row no-gutters">
                    <div class="col-6">
                        <div class="sm-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                            <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+0)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                        <div class="sm-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 1">
                            <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+1)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 image-container">
                        <div class="lg-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 2">
                            <img [src]="url+image['images'][2].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+2)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="equal-size" *ngIf="image['type']==='equal-size'">
                <div class="row no-gutters">
                    <div class="col-6">
                        <div class="eq-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                            <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+0)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="eq-image" style="margin: 0" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                            <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i*3+1)">
                                <img src="/assets/images/zoom.svg" alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single" *ngIf="image['type']==='single'">
                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="single-image" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                            <img [src]="url+image['images'][0].image"  alt="Triveni Spinning Mills Nepal gallery">
                            <div class="zoom" (click)="openImage(i)">
                                <img src="/assets/images/zoom.svg"  alt="Triveni Spinning Mills Nepal gallery">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
   </div>
   </div>
</div>
<!-- gallery -->
<div *ngIf="showImage" id="myModal" class="img-modal">
    <span (click)="hideModal()" class="close">&times;</span>
    <img class="modal-content" [src]="imgUrl"  alt="Triveni Spinning Mills Nepal gallery">
</div>
<div *ngIf="showImage" class="gallery-left-arrow" (click)='changeImage("left")'>
<p [ngClass]="{'inactive': !checkLeftArrow}">&larr;</p>
</div>
<div *ngIf="showImage" class="gallery-right-arrow" (click)='changeImage("right")'>
<p [ngClass]="{'inactive': !checkRightArrow}">&rarr;</p>
</div>