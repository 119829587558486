
<app-loader></app-loader>
<nav id="mainNav" class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container">
    <a class="navbar-brand" routerLink="/"><img src="/assets/images/triveni-sm-logo.svg" alt="triveni"></a>
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars" aria-hidden="true"></i>
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" ng-controller="HeaderController">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}">HOME <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/aboutus" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}">
            ABOUT US
          </a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/facilities" [routerLinkActive]="['is-active']">FACILITIES</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/products" [routerLinkActive]="['is-active']">PRODUCTS</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/contact" [routerLinkActive]="['is-active']">CONTACT</a>
        </li>
      </ul>
    </div>
  </div> <!-- /.container -->
</nav>
<div class="main-body">
  <router-outlet></router-outlet>
</div>
<footer>
 <div class="container">
   <div class="row">
     <div class="col-md-4 col-lg-5 mb-3">
       <h6>COMPANY</h6>
       <ul>
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/aboutus">About Us</a></li>
        <li><a routerLink="/facilities">Facilities</a></li>
        <li><a routerLink="/products">Products</a></li>
        <li><a routerLink="/contact">Contact</a></li>
       </ul>
     </div>
     <div class="col-md-5 col-lg-7 mb-3">
       <h6>{{ contact_info.heading }}</h6>
       <ul class="iconlist">
        <li><span class="icon-phone"></span> {{ contact_info.phone }}</li>
        <li><span class="icon-location"></span> <div [innerHTML]="contact_info.location"></div></li>
        <li><span class="icon-mail"></span> {{ contact_info.mail }}</li>
       </ul>
     </div>
     <div class="col-md-7 col-lg-5 cpr-text">
        © {{fullYear}} Triveni Spinning Mills. All Rights Reserved.
     </div>
     <div class="col-md-5 col-lg-7 cpr-text">
       <a href="http://kazistudios.com/" target="_blank">Site by Kazistudios</a>
    </div>
   </div>
 </div> 
</footer>

<!-- <footer>
  <div class="container">
   <div class="row">
    <div class="col-lg-3 quick-links">
      <h6>QUICK LINKS</h6>
      <ul>
       <li><a routerLink="/">Home</a></li>
       <li><a routerLink="/aboutus">About Us</a></li>
       <li><a routerLink="/facilities">Facilities</a></li>
       <li><a routerLink="/products">Products</a></li>
       <li><a routerLink="/contact">Contact</a></li>
      </ul>
    </div>
       <ng-container  *ngFor="let contact of contact_info">
           <div class="col-lg-3 contacts">
            <p class="icon"><span class="icon-phone"></span></p>
            <p class="text">{{ contact.phone }}</p>
           </div>
           <div class="col-lg-3 contacts">
            <p class="icon"><span class="icon-location"></span></p>
            <div class="text" [innerHTML]="contact.location">

            </div>
           </div>
           <div class="col-lg-3 contacts">
            <p class="icon"><span class="icon-mail"></span></p>
            <p class="text">{{ contact.mail }}</p>
           </div>
       </ng-container>

   </div>
   <div class="row">
    <div class="col-lg-3 cpr-text">
      © 2020 Triveni Spinning Mills. All Rights Reserved.
   </div>
   <div class="col-lg-3 offset-lg-6 cpr-text site-by">
     <a href="http://kazistudios.com/" target="_blank">Site by Kazistudios</a>
  </div>
   </div>
  </div>
</footer> -->
