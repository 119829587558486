
<!-- History -->
<div class="history-container">
    <div class="history-heading-overlay parallax-window-1"></div>
    <div class="opacity-overlay">
        <div class="container">
        <div class="history cm-padding">
            <div class="row">
                <div class="col-lg-4">
                    <h1 class="large-heading">History</h1>
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-once="true" data-aos-delay="400">
                    <p class="small-text" [innerHtml]="aboutusPagedata && aboutusPagedata.banner && aboutusPagedata.banner.title || ''">
                         
                    </p>
                    </div>
                </div>
                <div class="col-lg-7 offset-lg-1">

            <div class="expand-text">
                <!-- <div class="expand-button" (click)="expandText()">
                    <p class="text-center expand-icon" #expandIcon><i class="fa fa-chevron-circle-down" aria-hidden="true"></i></p>
                </div> -->
                <div class="text-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="500">

                <p class="text-justify text" [innerHtml]="aboutusPagedata && aboutusPagedata.banner && aboutusPagedata.banner.description || ''">
                </p>
                </div>
         
            </div>
            </div>
        </div>
        </div>
        </div>

    </div>
</div>
<!-- History -->

<!-- chairman -->
<div class= "desktop-only">
<div class="chairman img-right">
    <div class="description">
        <div class="description-container cm-padding">
            <h1 class="large-heading text-red">{{aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].title}}</h1>
            <div class="description-text" [innerHTML]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].description  | safe: 'html' || ''">
                <!-- <div class="text-container" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="400"  data-aos-once="true">
                    <p class="name">
                       Ram Chandra Sanghai 
                    </p>
                    </div>
                    <div class="text-container" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                <p class="designation">
                    Chairman
                </p>
                    </div>
                <div class="text-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="100">
                <q class="quotes-text">
                    In business, one should always be honest and committed to work. A person’s confidence takes them a long way. One must always be attached to their roots and never forget what they inherited from generations above them.  
                </q>
            </div>
            <div class="text-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="600"  data-aos-once="true">

                <p class="text text-justify">
                    A keen business learner who started work from early age of 23 years; Mr. RC Sanghai strongly believed in ways his father and elders brothers have adopted. He is immensely connected and driven by traditions which has made his fundamentals about business very strong. 
                    <br>
                    <br>
                    Under his leadership, the yarn industry under the Triveni Group umbrella has grown leaps and bounds despite many environmental disturbances. His business acumen and ability to make the right decisions at the right time have greatly stirred the business out of storms. He has single handedly taken his products to the international markets in Turkey and India which have added a great deal of exposure to the company. 
                    <br>
                    <br>
                    It is with his judgement of the ‘right people at the right place’ that he has an excellent team under him who strive on his direction to make Triveni Spinning Mills a great company. 
                </p>
                </div> -->
            </div>
        </div>
    </div>
    <div class="img-container">
        <img [src]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].image && url+aboutusPagedata.aboutus[0].image || ''" alt="Triveni Spinning Mills Nepal chairman">
    </div>
</div>
</div>
<div class= "mobile-only">
<div class="chairman cm-padding">
    <div class="container">
    <h1 class="large-heading text-red">{{aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].title}}</h1>
    <div class="img-container">
        <img [src]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].image && url+aboutusPagedata.aboutus[0].image || ''" alt="Triveni Spinning Mills Nepal chairman">
    </div>
    <div class="description">
        <div class="description-container">
            <div class="description-text"  [innerHTML]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[0] && aboutusPagedata.aboutus[0].description  | safe: 'html' || ''">
            </div>
        </div>
    </div>
    </div>
</div>
</div>
<div style="clear: both;"></div>
<!-- chairman -->

<!-- Growth -->
<div class= "desktop-only">
<div class="growth img-left">
    <div class="img-container">
        <img [src]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].image && url+aboutusPagedata.aboutus[1].image || ''" alt="Triveni Spinning Mills Nepal growth">
    </div>
    <div class="description bg-red">
        <div class="description-container cm-padding">
            <div class="description-text text-justify">
                <h1 class="large-heading">{{aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].title}}</h1>
                <div class="text-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true"  [innerHTML]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].description || ''">
                <!-- <p>
                    A company’s growth can be manifested through multiple dimensions. It is not only in terms of revenue that a company grows; a big factor is also the growth and health of its employees. 
                    <br>
                    <br>
                    Triveni Spinning Mills employs over 1900 employees who make the production of over 150MT per month a reality. The management of the company is handled by employees who have been providing their incomparable expertise since more than 25 years to the group. This speaks loud about the nature of management in terms of retaining what matters the most – its people. The company believes in providing the best resources to its employees to help facilitate personal growth and development.  
                    <br>
                    <br>
                    The result of a good team inevitably translates to exponential infrastructural growth from 13,000 spindles at commencement to 60,000 in under 2 decades. The industry’s exposure to exports has also grown unsurprisingly corelating to its growth in size. Triveni’s spinning industry thus has become a significant contributor to the national need of employment generation. 

                </p> -->
                </div>
            </div>
        </div>
    </div>
</div>    
</div>
<div class= "mobile-only">
    <div class="growth">
        <div class="description bg-red">
            <div class="container">
            <div class="description-container cm-padding">
                <h1 class="large-heading">{{aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].title}}</h1>
                <div class="img-container">
                    <img [src]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].image && url+aboutusPagedata.aboutus[1].image || ''" alt="Triveni Spinning Mills Nepal growth">
                </div>
                <div class="description-text text-justify">
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200" [innerHTML]="aboutusPagedata && aboutusPagedata.aboutus && aboutusPagedata.aboutus[1] && aboutusPagedata.aboutus[1].description || ''">
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>    
    </div>
<div style="clear: both;"></div>
<!-- Growth -->

<!-- Achievement -->
<div class="lg-desktop">
    <div class="achievement fixed-bg">
      <div class="white-overlay">
        <div class="container achievement-overlay">
            <div class= "fixed-box">
            <h1 class="large-heading text-red">Achievements</h1>
            <div class="top-content">
                <div class="row">
                    <div class="col-md-6 col-lg-4" style="margin-bottom: 30px;" *ngFor="let achievement of achievements; let i = index">
                        <div style="height: 100%;" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="400"  data-aos-once="true" [attr.data-aos-delay]="200 * i">
                        <div class="achievement-card bg-red">
                            <div class="row no-gutters" style="height: 100%;">
                                <div class="col-4">
                                    <div class="img-container">
                                        <img *ngIf="!achievement.image || achievement.image ===''" src="/assets/images/achievement.svg" alt="Triveni Spinning Mills Nepal achievement">
                                        <img *ngIf="achievement.image && achievement.image !==''" [src]="url+achievement.image" alt="Triveni Spinning Mills Nepal achievement">
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="description-box">
                                        <p class="text-justify">
                                         {{achievement['content']}}
                                        </p>
                                    </div>
                                </div>
                            </div>               
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
</div>
<div class="xlg-desktop">
    <div class="achievement fixed-bg">
      <div class="white-overlay">
        <div class="container achievement-overlay">
            <div class= "fixed-box">
            <h1 class="large-heading text-red">Achievements</h1>
            <div class="top-content">
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xl-3" style="margin-bottom: 30px;" *ngFor="let achievement of achievements; let i = index">
                        <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="400"  data-aos-once="true" [attr.data-aos-delay]="200 * i">
                        <div class="achievement-card bg-red">
                            <div class="row no-gutters">
                                <div class="col-4">
                                    <div class="img-container">
                                        <img *ngIf="achievement.url ===''" src="/assets/images/achievement.svg" alt="Triveni Spinning Mills Nepal achievement">
                                        <img *ngIf="achievement.url !==''" [src]="achievement.url" alt="Triveni Spinning Mills Nepal achievement">
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="description-box">
                                        <p class="text-justify">
                                         {{achievement['name']}}
                                        </p>
                                    </div>
                                </div>
                            </div>               
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
</div>
<!-- Achievement -->