import { Component, OnInit,  ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/api/api.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
import { RegexConst } from '../shared/constants/regex-constant';
import * as AOS from 'aos';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})
export class ContactComponent implements OnInit {

  contactPageData: any = {};
  url: any = environment.apiUrl+'/';
  regexConst = RegexConst;
  contactForm: FormGroup;
  submitted: boolean = false;
  fileUrl:any;
  fileName:any='';
  fileToUpload:any;
  loading:boolean=false;
  success:boolean = false;
  error: boolean = false;
  errorMsg: any = '';
  mapUrl: any = '';

  @ViewChild('name') name: ElementRef;
  @ViewChild('nameinput') nameinput: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('phoneinput') phoneinput: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('emailinput') emailinput: ElementRef;
  @ViewChild('message') message: ElementRef;
  @ViewChild('messageinput') messageinput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    public apiService: ApiService, 
    private loaderService : LoaderService,
    private sanitizer: DomSanitizer
  ) {
    this.createForm();
    this.loaderService.showLoader();
    this.apiService.getcontactPageData().subscribe(
			res => {
        this.contactPageData = res;
        this.mapUrl = sanitizer.bypassSecurityTrustResourceUrl(this.contactPageData.contact.map_location);
        this.loaderService.hideLoader();
        AOS.init();
		}, error =>{
			this.loaderService.hideLoader();
		});
   }

  ngOnInit(): void {
    AOS.init();
    this.contactForm.valueChanges.subscribe((data)=>{
      if(data['name'] !== "" && data['name'] !== null){
        this.renderer.addClass(this.name.nativeElement, 'value-entered');
        this.renderer.addClass(this.nameinput.nativeElement, 'input-entered');
      }else{
        this.renderer.removeClass(this.name.nativeElement, 'value-entered');
        this.renderer.removeClass(this.nameinput.nativeElement, 'input-entered');
      }
      if(data['contact_no'] !== null && data['contact_no'] !== ""){
        this.renderer.addClass(this.phone.nativeElement, 'value-entered');
        this.renderer.addClass(this.phoneinput.nativeElement, 'input-entered');
      }else{
        this.renderer.removeClass(this.phone.nativeElement, 'value-entered');
        this.renderer.removeClass(this.phoneinput.nativeElement, 'input-entered');
      }
      if(data['email'] !== "" && data['email'] !== null){
        this.renderer.addClass(this.email.nativeElement, 'value-entered');
        this.renderer.addClass(this.emailinput.nativeElement, 'input-entered');
      }else{
        this.renderer.removeClass(this.email.nativeElement, 'value-entered');
        this.renderer.removeClass(this.emailinput.nativeElement, 'input-entered');
      }
      if(data['message'] !== "" && data['message'] !== null){
        this.renderer.addClass(this.message.nativeElement, 'value-entered');
        this.renderer.addClass(this.messageinput.nativeElement, 'textarea-entered');
      }else{
        this.renderer.removeClass(this.message.nativeElement, 'value-entered');
        this.renderer.removeClass(this.messageinput.nativeElement, 'textarea-entered');
      }
    })
  }
  createForm(){
    this.contactForm = this.fb.group({
      name:['',Validators.required],
      email:['',[Validators.required, Validators.pattern(this.regexConst.email)]],
      contact_no:['',Validators.required],
      message:['',Validators.required],
    });
  }
  handleFileInput(file:FileList){
    this.fileToUpload=file.item(0);
    var reader=new FileReader();
    reader.onload=(event:any)=>{
      this.fileUrl=event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    this.fileName = this.fileToUpload['name'];
  }
  submit(){
    this.submitted=true;
    if(this.contactForm.invalid){
      return;
    }
    let formData = new FormData();
    formData.append('name',this.contactForm.value['name'])
    formData.append('email',this.contactForm.value['email'])
    formData.append('message',this.contactForm.value['message'])
    formData.append('phone',this.contactForm.value['contact_no'])
    formData.append('cv',this.fileToUpload);
    this.loading = true;
    this.apiService.postContactus(formData).subscribe((res: any)=>{
    this.loading = false;
    if(!res.loading){
      this.success = true;
      setTimeout(()=>{
        this.success = false;
      },2000)
    }
    this.fileToUpload = null;
    this.contactForm.reset();
    this.fileName = '';
    this.submitted = false;
  },error => {
    this.loading = false;
    this.submitted = false;
    this.errorMsg = error.error.errors.file[0];
    this.error = true;
    setTimeout(()=>{
      this.error = false;
    },3000)
  })
  }

}
