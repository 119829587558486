import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
declare var $, parallax;
import * as AOS from 'aos';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit, AfterViewInit, OnDestroy {

  aboutusPagedata:any = {};
  url: any = environment.apiUrl+'/';
  achievements = [];
  
  constructor(public apiService: ApiService, private loaderService : LoaderService) {
    this.loaderService.showLoader();
    this.apiService.getAboutusPageData().subscribe(
			res => {
				this.aboutusPagedata = res;
        this.loaderService.hideLoader();
        $('.parallax-window-1').parallax({src: this.aboutusPagedata && this.aboutusPagedata.banner && this.aboutusPagedata.banner.media && this.url+this.aboutusPagedata.banner.media || '', bleed: '50', speed: '0.6'});
        AOS.init();
        this.achievements = this.aboutusPagedata.achievements;
		}, error =>{
			this.loaderService.hideLoader();
		});
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{

  }

  ngOnDestroy(): void{
    $('.parallax-window-1').parallax('destroy');
  }

}
