import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
declare var $, parallax;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  homePageData: any = {};
  url: any = environment.apiUrl+'/';
  check: boolean = false;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(public apiService: ApiService, private loaderService : LoaderService) { 
    this.loaderService.showLoader();
    this.apiService.getHomePageData().subscribe(
			res => {
        this.homePageData = res;
        this.check = true;
        this.loaderService.hideLoader();
        AOS.init();
        $('.parallax-window').parallax({src: this.homePageData && this.homePageData.static_data_section_2 && this.homePageData.static_data_section_2.image && this.url+this.homePageData.static_data_section_2.image || '', bleed: '50', speed: '0.6'});

		}, error =>{
			this.loaderService.hideLoader();
		});
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void{

  }
  ngOnDestroy(): void{
    $('.parallax-window').parallax('destroy');
  }

}
