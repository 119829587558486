import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';
import { Loader } from '../model/loader';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        transform: 'translateY(0%)'
      })),
      state('closed', style({
        transform: 'translateY(100%)'
      })),
      transition('open => closed', [
        animate('0.5s 100ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('0.5s 100ms ease-in-out')
      ]),
    ]),
  ],
})
export class LoaderComponent implements OnInit {
  show: boolean;
  showCounter: number = 0;
  hideCounter: number = 0;
  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.loaderState.subscribe((state: Loader) => {
      if(state.show){
        this.showCounter = this.showCounter + 1;
      }
      else{
        this.hideCounter = this.hideCounter + 1;
      }
      if(this.showCounter>this.hideCounter){
        this.show = true;
      }
      else{
        this.show = false;
        this.showCounter = 0;
        this.hideCounter = 0;
      }
    });
  }

}
