<!-- <div [@openClose]="show ? 'open' : 'closed'" class="fixed"> -->
    <div *ngIf="show" class="fixed">
        <div class="loader-container">
            <div class="loader-1">
                <div class="loader-2">
                </div>
                <div class="loader-3">
                </div>  
                <div class="rope-1">
                </div>
                <div class="rope-2">
                </div>
                </div>
        </div>
    </div>
