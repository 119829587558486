
<!-- video -->
<div class="video">

    <video id="myVideo" #videoPlayer *ngIf="homePageData && homePageData.banner && homePageData.banner.media" loop autoplay muted>
        <source src="{{homePageData && homePageData.banner && homePageData.banner.media && url+homePageData.banner.media || ''}}" type="video/mp4">
      </video>
<div class="opacity-overlay">
</div>
      <div class="text-container">
          <div class="title-text container">
             <div class="row">
               <div class="col-lg-4">
                <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-once="true"> 
                <p class="medium-text">{{homePageData && homePageData.banner && homePageData.banner.title || ''}}</p>
                </div>
                <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="400" data-aos-duration="600" data-aos-offset="200" data-aos-once="true"> 
                <p class="small-text">
                  {{homePageData && homePageData.banner && homePageData.banner.description || ''}}</p>
                </div>
                </div>

             </div>
          </div>
      </div>
      
</div>
<!-- video -->
<!-- about the company -->
<div class="about-container bg-red">
    <div class="about-overlayer">
        <img src="/assets/images/spinning-wheel.svg" alt="Triveni Spinning Mills Nepal about the company">
    </div>
    <div class="container">
        <div class="about">
            <div class="row">
              <div class="col-lg-5 heading">
              </div>
                <div class="col-lg-7">
            <div style="margin-bottom: 30px;">
              <h1 class="large-heading">{{homePageData && homePageData.static_data_section_1 && homePageData.static_data_section_1.title || ''}}</h1>
            </div>
            <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-once="true" data-aos-offset="150">
              <div class="about-description" [innerHTML]="homePageData && homePageData.static_data_section_1 && homePageData.static_data_section_1.content || ''">

              </div>
              </div>
              <div data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="200" data-aos-duration="600" data-aos-once="true" data-aos-offset="150"> 
              <button [routerLink]="homePageData && homePageData.static_data_section_1 && homePageData.static_data_section_1.link || ''" class="btn-2">Learn more</button>
              </div>
            </div>
        </div>
        </div>
        </div>

  
</div>
<!-- about the company -->

<!-- products -->
<div class="products-container">
    <div class="products-heading-overlay parallax-window"></div>
    <div class="products-heading opacity-overlay">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <!-- <h1 class="large-heading">Products</h1> -->    
            <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-once="true"  data-aos-offset="200">  
              <p class="details-highlight">
                {{homePageData && homePageData.static_data_section_2 && homePageData.static_data_section_2.title || ''}}
              </p>
              </div>
              <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-once="true"  data-aos-delay="200" data-aos-offset="200">
            <div class="homeprod-description" [innerHTML]="homePageData && homePageData.static_data_section_2 && homePageData.static_data_section_2.content || ''">
            </div>
            </div>
            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="400" data-aos-offset="200" data-aos-duration="500" data-aos-once="true"> 
            <button [routerLink]="homePageData && homePageData.static_data_section_2 && homePageData.static_data_section_2.link || ''"  class="btn-2">View Products</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- products -->


