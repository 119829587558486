
<!-- products heading -->
<div class="products-container">
    <div class="products-heading-overlay parallax-window-3"></div>
    <div class="opacity-overlay">
        <div class="container">
        <div class="products cm-padding">
            <div class="row no-gutters">
                <div class="col-lg-4">
                <div class="text-container">
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="400">
                        <p class="details-highlight" [innerHtml]="productsPageData && productsPageData.banner && productsPageData.banner.title || ''">

                        </p>
                        <p class="small-text" [innerHtml]="productsPageData && productsPageData.banner && productsPageData.banner.description || ''">
    
                        </p>
                    </div>
                <!-- <p class="text-justify text">
                    We are capable of offering a variety of synthetic yarns with a range of attributes including superior strength & durability. For all of your weaving & knitting needs, our synthetic yarn is available in a variety of blends & counts.
                </p> -->
                </div>
            </div>
        </div>
        </div>
        </div>

    </div>
</div>
<!-- products heading-->

<!-- products -->
<div class="products bg-red cm-padding">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-7 order-2 order-lg-1">
                <div class="description-box">
                    <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                    <p class="medium-text" [innerHTML]="productsPageData && productsPageData['static_data_section_1'] && productsPageData['static_data_section_1'].title || ''">
                      
                    </p>
                    </div>
              
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="200"  data-aos-once="true">
                        <div class="text-desc" [innerHTML]="productsPageData && productsPageData['static_data_section_1'] && productsPageData['static_data_section_1'].content || ''">

                        </div>
                </div>
                </div>
            </div>
            <div class="col-lg-5 order-1 order-lg-2">
                <div class="img-container">
                    <img [src]="productsPageData && productsPageData['static_data_section_1'] && productsPageData['static_data_section_1'].image && url + productsPageData['static_data_section_1'].image || ''"  alt="Triveni Spinning Mills Nepal products">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- products -->


<!-- gallery -->
<div class="gallery fixed-bg">
    <div class="white-overlay">
    <div class="container">
        <div class="fixed-box">
     <h1 class="large-heading text-red">Our Products</h1>
     <div class="gallery-container">
         <div *ngFor="let image of imagesFixed; let i = index">
             <div class="single-left" *ngIf="image['type']==='single-left'">
                 <div class="row no-gutters">
                     <div class="col-6">
                         <div class="lg-image image-container"  data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                                 <img [src]="url+image['images'][0].image"  alt="Triveni Spinning Mills Nepal gallery">
                                 <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][0].pdf" target="_blank" *ngIf="image['images'][0].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                 <div class="description-box">
                                     <p>{{image['images'][0].title}}</p>
                                 </div>
                         </div>
                     </div>
                     <div class="col-6">
                         <div class="sm-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 1">
                                 <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                                 <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][1].pdf" target="_blank" *ngIf="image['images'][1].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                 <div class="description-box">
                                    <p>{{image['images'][1].title}}</p>
                                </div>
                         </div>
                         <div class="sm-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 2">
                                <img [src]="url+image['images'][2].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][2].pdf" target="_blank" *ngIf="image['images'][2].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][2].title}}</p>
                                </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="single-right" *ngIf="image['type']==='single-right'">
                 <div class="row no-gutters">
                     <div class="col-6">
                         <div class="sm-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                                <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][0].pdf" target="_blank" *ngIf="image['images'][0].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][0].title}}</p>
                                </div>
                         </div>
                         <div class="sm-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 1">
                                <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][1].pdf" target="_blank" *ngIf="image['images'][1].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][1].title}}</p>
                                </div>
                         </div>
                     </div>
                     <div class="col-6">
                         <div class="lg-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 2">
                                <img [src]="url+image['images'][2].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][2].pdf" target="_blank" *ngIf="image['images'][2].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][2].title}}</p>
                                </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="equal-size" *ngIf="image['type']==='equal-size'">
                 <div class="row no-gutters">
                     <div class="col-6">
                         <div class="eq-image image-container" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                                <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][0].pdf" target="_blank" *ngIf="image['images'][0].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][0].title}}</p>
                                </div>
                         </div>
                     </div>
                     <div class="col-6">
                         <div class="eq-image image-container" style="margin: 0" data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200 * 1">
                                <img [src]="url+image['images'][1].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][1].pdf" target="_blank" *ngIf="image['images'][1].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][1].title}}</p>
                                </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="single" *ngIf="image['type']==='single'">
                 <div class="row no-gutters">
                     <div class="col-12">
                         <div class="single-image image-container"  data-aos="fade" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                                <img [src]="url+image['images'][0].image" alt="Triveni Spinning Mills Nepal gallery">
                                <div class="zoom" data-toggle="tooltip" title="Download a pdf">
                                    <a [href]="url+image['images'][0].pdf" target="_blank" *ngIf="image['images'][0].pdf">
                                        <img src="/assets/images/download.svg" alt="Triveni Spinning Mills Nepal gallery">
                                    </a>
                                </div>
                                <div class="description-box">
                                    <p>{{image['images'][0].title}}</p>
                                </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     </div>
    </div>
    </div>

 </div>
 <!-- gallery -->