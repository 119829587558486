import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'client';
  aboutUs: boolean = false;
  social_sites: any =[{
  }];
  fullYear: Number = new Date().getFullYear();
  contact_info: any ={
  };

  constructor(private router: Router, private loaderService: LoaderService, public apiService: ApiService) { 
    this.apiService.getFooter().subscribe(
			res => {
        this.contact_info.phone = res['factory_address_phone_1'];
        this.contact_info.mail = res['factory_address_email'];
        this.contact_info.location = res['factory_address_location'];
		}, error =>{
		});
  }

  ngOnInit(){
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd){
        window.scrollTo({
         top: 0,
         behavior: 'smooth',
       });
     }
    });
  }
  ngAfterViewInit(){

  }

}
