
<!-- address -->
<div class="ibox">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <div class="bg-card">
                <div class="factory-address address-card">
                    <h1 class="text-red">Factory Address</h1>
                    <div class="content">
                        <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                            <p class="name" [innerHTML]="contactPageData && contactPageData.contact && contactPageData.contact.factory_address_contact_person || ''">
                            
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                            <div class="address"  [innerHTML]="contactPageData && contactPageData.contact && contactPageData.contact.factory_address_location || ''">
                            
                            </div>
                        </div>
                       <ul class="iconlist">
                           
                           <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="300">
                               <span class="icon-phone"></span>: {{contactPageData && contactPageData.contact && contactPageData.contact.factory_address_phone_1}}
                               </div>
                           </li>
                           <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="400">
                            <span class="icon-phone"></span>:  {{contactPageData && contactPageData.contact && contactPageData.contact.factory_address_phone_2}}
                            </div>
                        </li>
                        <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="500">
                            <span class="icon-mail"></span>: {{contactPageData && contactPageData.contact && contactPageData.contact.factory_address_email}}
                            </div>
                        </li>
                       </ul>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="bg-card">
                <div class="office-address address-card">
                    <h1 class="text-red">Office Address</h1>
                    <div class="content">
                        <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                        <p class="name" [innerHTML]="contactPageData && contactPageData.contact && contactPageData.contact.office_address_contact_person || ''">
                            
                        </p>
                        </div>
                        <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                        <div class="address"  [innerHTML]="contactPageData && contactPageData.contact && contactPageData.contact.office_address_location || ''">
                            
                        </div>
                        </div>
                       <ul class="iconlist">
                           <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="300">
                               <span class="icon-phone"></span>: {{contactPageData && contactPageData.contact && contactPageData.contact.office_address_phone_1}}
                               </div>
                           </li>
                           <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="400">
                            <span class="icon-phone"></span>: {{contactPageData && contactPageData.contact && contactPageData.contact.office_address_phone_2}}
                            </div>
                        </li>
                        <li>
                            <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="500">
                            <span class="icon-mail"></span>:  {{contactPageData && contactPageData.contact && contactPageData.contact.office_address_email}}
                            </div>
                        </li>
                       </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- address -->

<!-- get-in-touch -->
<div class="get-in-touch bg-red">
    <div class="ibox">
        <div class="container form-container">
            <h1 class="large-heading">GET IN TOUCH</h1>
            <form class="user" [formGroup]="contactForm">
            <div class="row no-gutters">
                <div class="col-md-6 order-1 order-md-1">
                    <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true">
                    <div class="ts-form-group">
                        <input #nameinput type="text" formControlName="name">
                        <label #name>Name</label>
                        <div class="error-msg-container" *ngIf="contactForm.controls['name'].invalid && submitted">
                            <span class="error-msg">
                              Name is required.
                            </span>
                          </div>
                    </div>
                    </div>
                    <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="300">
                    <div class="ts-form-group">
                        <input #phoneinput type="number" formControlName="contact_no">
                        <label #phone>Phone</label>
                        <div class="error-msg-container"   *ngIf="contactForm.controls['contact_no'].invalid && submitted">
                            <span class="error-msg">
                              Contact number is required.
                            </span>
                          </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 order-3 order-md-2">
                    <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                    <div class="ts-form-group">
                        <textarea #messageinput type="text" formControlName="message"></textarea>
                        <label #message>Message</label>
                        <!-- <div class="error-msg-container"   *ngIf="contactForm.controls['message'].invalid && submitted">
                            <span class="error-msg">
                              Message is required.
                            </span>
                          </div> -->
                    </div>
                    </div>
                </div>
                <div class="col-md-12 order-2 order-md-3">
                    <div data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="400">
                    <div class="ts-form-group">
                        <input #emailinput type="text" formControlName="email">
                        <label #email>E-mail</label>
                        <div class="error-msg-container"   *ngIf="contactForm.controls['email'].invalid && submitted">
                            <span class="error-msg">
                              Valid email is required.
                            </span>
                          </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-6 order-4 job">
                    <div class="button-group">
                    <div class="row">
                      <div class="col-8" style="padding-top: 10px;">
                          <span class="spaced-text">I'M APPLYING FOR A VACANT JOB</span>
                      </div>
                      <div class="col-4 upload">
                        <div data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200">
                          <label for="upload">
                            <a class="btn btn-2"><span></span>Upload a CV</a>
                               <input (change)="handleFileInput($event.target.files)" type="file" id="upload" style="display:none">
                         </label>
                          </div>
                        </div>
                        <div class="col-12">
                            <div class="file-name">{{fileName}}</div>
                          </div>
                    </div>
                    </div>

                  </div>
                  <div class="col-md-12 order-5">
                    <div data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"  data-aos-once="true" data-aos-delay="200"  data-aos-offset="-50">
                    <div class="button-group">
                    <div class="send-btn">
                        <div *ngIf="loading">
                            Sending<div class="spinner-grow" role="status">
                                <span class="sr-only">Sending...</span>
                              </div>
                              <div class="spinner-grow" role="status">
                                  <span class="sr-only">Sending...</span>
                                </div>
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Sending...</span>
                                  </div>
                        </div>
                        <button *ngIf="!loading" (click)="submit()" class="btn-2"><span></span>Send Now</button>
                        <div *ngIf="success" class="success-msg" [@fadeInOut]>
                            Message successfully sent!
                          </div>
                          <div *ngIf="error" class="error-msg" [@fadeInOut]>
                            {{errorMsg}}
                          </div>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>
<!-- get-in-touch -->

<!-- map -->
    <div class="map" *ngIf="mapUrl !== ''">
        <iframe [src]="mapUrl" width="100%" height="600" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
<!-- map -->