import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
declare var bodyScrollLock;
declare var $, parallax;


@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit, AfterViewInit, OnDestroy {

  facilitiesPageData: any = {};
  url: any = environment.apiUrl+'/';
  facilities:any[] =[];
  images: any[] = [];
  imagesFixed: any[] = [];
  imgUrl:string='';
  showImage:boolean=false;
  totalImage: number = 0;
  countImage: number = 0;
  checkLeftArrow:boolean = true;
  checkRightArrow:boolean = true;
  currentIndex:number = -1;
  isImgLoaded:boolean = false;
  isImgLoaded1:any = [];

  constructor(public apiService: ApiService, private loaderService : LoaderService) {
    this.loaderService.showLoader();
    this.apiService.getFacilitiesPageData().subscribe(
			res => {
				this.facilitiesPageData = res;
        this.loaderService.hideLoader();
        AOS.init();
        this.facilities = this.facilitiesPageData.facilities;
        this.images = this.facilitiesPageData.gallery_images;
        // setTimeout(()=>{
        //   $('.parallax-window-2').parallax({src: this.facilitiesPageData && this.facilitiesPageData.banner && this.facilitiesPageData.banner.media && this.url+this.facilitiesPageData.banner.media || '', bleed: '50', speed: '0.6'});
        // },200);
        this.processGallery();
		}, error =>{
			this.loaderService.hideLoader();
		});
   }

  ngOnInit(): void {

  
  }

  processGallery(){
    let count = true;
    let tempData = [];
    let rem = this.images.length - (this.images.length) % 3;
    for(let i = 0; i < rem; i++){
      tempData.push(this.images[i]);
      if(tempData.length === 3){
        if(count){
          this.imagesFixed.push({'images':tempData, 'type':'single-left'});  
        }
        else{
          this.imagesFixed.push({'images':tempData, 'type':'single-right'});
        }
        count = !count;
          tempData = [];
      }
    }
    if((this.images.length) % 3 === 1){
      this.imagesFixed.push({'images':[this.images[this.images.length-1]], 'type':'single'});  
    }
    if((this.images.length) % 3 === 2){
      tempData.push(this.images[this.images.length-2]);
      tempData.push(this.images[this.images.length-1]);
      this.imagesFixed.push({'images':tempData, 'type':'equal-size'});  
    }
  }

  openImage(i){
    console.log(i);
    this.imgUrl=this.url+this.images[i].image;
    this.currentIndex = i;
    this.checkCondition();
    this.showImage=true;
    bodyScrollLock.disableBodyScroll();
  }
  hideModal(){
    this.showImage=false;
    bodyScrollLock.enableBodyScroll();
  }

  countImageLoaded(n){
    this.countImage = this.countImage + n;
    if(this.countImage === this.totalImage){
      this.isImgLoaded = true;
    }
  }

  checkImageLoaded(i){
    this.isImgLoaded1[i]=true;
  }
  changeImage(di){
    if(di==="left" && this.checkLeftArrow ){
      this.currentIndex= this.currentIndex-1;
    }
    if(di==="right" && this.checkRightArrow ){
      this.currentIndex= this.currentIndex+1;
    }
    this.imgUrl = this.url+this.images[this.currentIndex].image;
    this.checkCondition();

  }

  checkCondition(){
    if(this.currentIndex===0){
      this.checkLeftArrow = false;
    }
    else{
      this.checkLeftArrow = true;
    }
    if(this.currentIndex === this.images.length-1){
      this.checkRightArrow = false;
    }
    else{
      this.checkRightArrow = true;
    }
  }

  ngAfterViewInit(): void{

  }

  ngOnDestroy(): void{
    $('.parallax-window-2').parallax('destroy');
  }

}
