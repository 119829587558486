import { Component, OnInit, ɵConsole, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
declare var $, parallax;
import * as AOS from 'aos';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit, OnDestroy {

  productsPageData: any = {};
  url: any = environment.apiUrl+'/';
  images: any[] = [];
  imagesFixed: any[] = [];

  constructor(public apiService: ApiService, private loaderService : LoaderService) {
    this.loaderService.showLoader();
    this.apiService.getProductsPageData().subscribe(
			res => {
				this.productsPageData = res;
        this.loaderService.hideLoader();
        AOS.init();
        this.images = this.productsPageData.ourproducts;
        setTimeout(()=>{
          $('.parallax-window-3').parallax({src: this.productsPageData && this.productsPageData.banner && this.productsPageData.banner.media && this.url+this.productsPageData.banner.media || '', bleed: '50', speed: '0.6'});
        },200);
        this.processGallery();
		}, error =>{
			this.loaderService.hideLoader();
		});
   }

  ngOnInit(): void {


  }
  processGallery(){
    let count = true;
    let tempData = [];
    let rem = this.images.length - (this.images.length) % 3;
    for(let i = 0; i < rem; i++){
      tempData.push(this.images[i]);
      if(tempData.length === 3){
        if(count){
          this.imagesFixed.push({'images':tempData, 'type':'single-left'});  
        }
        else{
          this.imagesFixed.push({'images':tempData, 'type':'single-right'});
        }
        count = !count;
          tempData = [];
      }
    }
    if((this.images.length) % 3 === 1){
      this.imagesFixed.push({'images':[this.images[this.images.length-1]], 'type':'single'});  
    }
    if((this.images.length) % 3 === 2){
      tempData.push(this.images[this.images.length-2]);
      tempData.push(this.images[this.images.length-1]);
      this.imagesFixed.push({'images':tempData, 'type':'equal-size'});  
    }
  }

ngAfterViewInit(): void{
 
}

ngOnDestroy(): void{
  $('.parallax-window-3').parallax('destroy');
}


}
